<template>
  <div class="box">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed': ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="">
    </div>
    <div class="banner">
      <img src="/subject/system/banner.png" />
    </div>
    <div class="center">
      <div class="center_view">
        <div class="titleStyle">
          <img src="/subject/system/title_1.png" />
        </div>
        <div class="model_1">
          <img src="/subject/system/model_bj.png" />
          <div class="model_1_center">
            <img src="/subject/system/model_1.png" />
            <div class="link_button" @click="openLogin">前往用户中心</div>
            <div class="model_tetx">
              <img src="/subject/system/model_tetx.png" />
            </div>
          </div>
        </div>
        <div class="titleStyle">
          <img src="/subject/system/title_2.png" />
        </div>
        <div class="model_2">
          <img src="/subject/system/model_bj_2.png" />
          <div class="model_2_center">
            <img src="/subject/system/model_text_2.png"  style="margin-bottom:40px;"/>
            <img src="/subject/system/model_table.png" />
            <div style="font-size:36px; color:#e8544f; text-align: center; margin: 20px 0 25px;">
              每升一级，享受更高的点差优惠<br />级数越高，越省钱
            </div>
            <img src="/subject/system/model_2_down.png" @click="downLoad"/>
          </div>
        </div>
        <div class="titleStyle">
          <img src="/subject/system/title_3.png" />
        </div>
        <div class="model_3">
          <img src="/subject/system/pcBack.png" />
          <div class="model_3_center">
            <img src="/subject/system/model_text_3.png" style="margin-bottom:30px;"/>
            <!-- <img src="/subject/system/model_table_3.jpg" style="margin-bottom:30px;"/> -->
            <img src="/subject/system/pc.png" style="margin-bottom:20px;"/><br/>
            <img src="/subject/system/model3_button.png" @click="openZJD"/>
          </div>
        </div>
        <div class="titleStyle">
          <img src="/subject/system/title_4.png" />
          <div class="positionImg">
            <img src="/subject/system/title_img.png" />
          </div>
        </div>
        <div class="model_4">
          <img src="/subject/system/model_bj_4.png" />
          <div class="model_4_center">
            <img src="/subject/system/model4_img.png" style="margin-bottom:30px;"/>
            <div class="model_4_text">
              层级只升不降<br />为您保留层级  近期无交易打算  也不会有降级风险
            </div>
            <div class="model_4_button">
              <img src="/subject/system/model4_button.png" @click="downLoad"/>
              <img src="/subject/system/model4_button_2.png" @click="live800"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contactusVue></contactusVue>
  </div>
</template>
<script>
import ContactusVue from "../../Contactus.vue";
export default {
  name: 'system',
  components:{
    ContactusVue
  },
  data() {
    return {
      screenWidth: null
    }
  },
  created() {
    // 传给App.vue,不展示tabbar
     this.$emit('controlShow',true);
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })()
    }
  },
  watch: {
    screenWidth: function (n) {
      if (n <= 500) {
        this.$router.push({
          name:'systemWap',
        })
      }
    }
  },
  methods: {
    // 客服链接
    live800(){
      window.open(
        this.$parent.live800UrlUc
        // 'https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377'
      );
    },
    downLoad(){
      window.open('https://oss.0790jiaxiao.com/HS/software/hs_9999.apk');
    },
    openLogin(){
      window.open('https://www.rlcvipltd.net/uc/login');
    },
    openZJD() {
      window.open('https://www.rlcvipltd.net/uc/specialEvent/earnBonus');
    },
    goback(){
      window.location.href = window.location.origin
    }
  }
}
</script>
<style lang="less" scoped>
.box .tabbar img{
    margin: 20px 50px;
    width: 130px;
}
.banner {
  font-size: 0;
  > img {
    width: 100%;
  }
}
.center {
  width: 100%;
  padding-bottom: 133px;
  background-image: url('/subject/system/bj.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .center_view {
    width: 1329px;
    margin: 0 auto;
    overflow: hidden;
    .titleStyle {
      text-align: center;
      font-size: 0;
      margin: 42px 0;
      position: relative;
      .positionImg {
        position: absolute;
        right: 230px;
        top: 58px;
        z-index: 2;
      }
    }
    .model_1 {
      width: 100%;
      position: relative;
      .model_1_center {
        width: 853px;
        height: calc(100% - 78px);
        position: absolute;
        top: 78px;
        left: 50%;
        margin-left: -426px;
        .link_button {
          position: absolute;
          width: 318px;
          height: 89px;
          border: 1px solid #e8544f;
          line-height: 89px;
          text-align: center;
          font-size: 39px;
          color: #e8544f;
          border-radius: 89px;
          cursor: pointer;
          right: 64px;
          bottom: 190px;
        }
        .model_tetx {
          position: absolute;
          text-align: center;
          width: 100%;
          bottom: 50px;
          left: 0;
        }
      }
    }
    .model_2 {
      position: relative;
      .model_2_center {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 100px 0 0;
        text-align: center;
      }
    }
    .model_3 {
      position: relative;
      .model_3_center {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 100px 0 0;
        text-align: center;
      }
    }
    .model_4 {
      position: relative;
      .model_4_center {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 100px 0 0;
        text-align: center;
        .model_4_text {
          font-size: 36px;
          color: #c1a368;
        }
        .model_4_button {
          margin-top: 38px;
          > img {
            cursor: pointer;
            &:nth-child(1) {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
